import React from 'react';
//gatsby
import { Link, graphql } from 'gatsby';
//seo
import Seo from 'gatsby-plugin-wpgraphql-seo';
//styles
import styled from 'styled-components';
import { rem } from 'polished';
import vars from 'components/styles/varss';
//components
import Layout from 'components/ui/layout';
import Container from 'components/ui/container';
import Heading from 'components/ui/heading';
import Icon from 'components/ui/icon';
import Small from 'components/resources/small';
//grid
import { Row, Col } from 'styled-bootstrap-grid';
//parser
import parse from "html-react-parser";

//styled
const ArticleArchiveWrap = styled.div`
  position: relative;
  padding-top: ${rem('100px')};
`;

const ArticleNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: ${rem('48px')};
    height: ${rem('48px')};
    margin-left: ${rem('10px')};
    margin-right: ${rem('10px')};
    border-radius: 50%;
    color: #fff;
    background-color: ${vars.colors.purple};
    &:hover {
      background-color: #6F54F9;
      color: #fff;
    }
    &:not([href]):not([tabindex]) {
      color: ${vars.colors.grey4};
      background-color: ${vars.colors.grey1};
      &:hover {
        color: ${vars.colors.grey4};
      }
    }
  }
`;

const ArticleCTA = styled.div`
  position: relative;
  margin-top: ${rem('100px')};
`;

const ArticleArchive = ({
  data,
  pageContext: {
    nextPagePath,
    previousPagePath,
    articlesTotalPages,
    pageNumber
  } }) => {

  const articles = data.allWpArticle.nodes;
  const postsPerPage = data.wp.readingSettings.postsPerPage;

  if (!articles.length) {
    return (
      <Layout isResources={true}>
        <Seo
          title={`${data.allWpArticleCategory.nodes[0].name} - Refersion`}
          postSchema={JSON.parse(
            data.wp.seo.contentTypes.article.schema.raw
          )}
        />
        <p>No articles found.</p>
      </Layout>
    )
  }

  return (
    <Layout isResources={true}>
      <Seo
        title={`${data.allWpArticleCategory.nodes[0].name} - Refersion`}
        postSchema={JSON.parse(
          data.wp.seo.contentTypes.article.schema.raw
        )}
      />
      <ArticleArchiveWrap>
        <Container>
          <Heading as="h1" marginBottom="60px">{data.allWpArticleCategory.nodes[0].name}</Heading>
          <Row>
            {articles.map((article) => (
              <Col col xs={12} sm={6} md={4} key={article.id}>
                <Small post={article} withCategory />
              </Col>
            ))}
          </Row>
          {articles.length > postsPerPage ? (
            <ArticleNav>
              <Link to={previousPagePath}><Icon icon="caret-left" size={12} /></Link>
              <Link to={nextPagePath}><Icon icon="caret-right" size={12} /></Link>
            </ArticleNav>
          ) : null}
        </Container>
        <ArticleCTA className="wp-front">
          {!!data.wp.blogCtas.articlesCTA && (
            parse(data.wp.blogCtas.articlesCTA)
          )}
        </ArticleCTA>
      </ArticleArchiveWrap>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($offset: Int!, $postsPerPage: Int!, $slug: String!) {
    wp {
      readingSettings {
        postsPerPage
      }
      blogCtas {
        articlesCTA
      }
      seo {
        contentTypes {
          article {
            schema {
              raw
            }
          }
        }
      }
    }
    allWpArticle ( 
      limit: $postsPerPage, 
      skip: $offset
      filter: {articleCategories: {nodes: {elemMatch: {slug: {eq: $slug}}}}, status: {eq: "publish"}}
      ) {
      nodes {
        id
        title
        uri
        excerpt
        nodeType
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 460, quality: 100)
              }
            }
          }
        }
        articleCategories {
          nodes {
            uri
            name
            id
          }
        }
      }
    }
    allWpArticleCategory(filter: {slug: {eq: $slug}}) {
      nodes {
        id
        name
      }
    }
  }
`

export default ArticleArchive;